<template>
  <div>
    <p class="alert alert-secondary mb-4">
      Choose an email template you have created, it will need to be published to
      be visible in this list.
      <router-link to="/marketing-email-templates"
        >Manage Email Templates</router-link
      >
    </p>
    <form @submit.prevent="next">
      <div class="row mb-4">
        <div class="col">
          <h5 class="text-secondary fw-bolder">Choose your email template</h5>
          <select
            v-model="formCopy.marketing_email_template_id"
            class="form-control"
          >
            <option value="">Select email template</option>
            <option v-for="t in templates" :key="t.id" :value="t.id">
              {{ t.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <p class="mb-0 fw-bold">
            Manage your email templates or
            <router-link to="/marketing-email-templates"
              >click here to create one now</router-link
            >
          </p>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <h5 class="text-secondary fw-bolder">Enter an Email Subject</h5>
          <input type="text" v-model="formCopy.subject" class="form-control" />
        </div>
      </div>

      <div class="row">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="
              !formCopy.marketing_email_template_id ||
              !templates.length ||
              !(formCopy.subject.length > 0)
            "
          >
            <i class="far fa-arrow-right me-2"></i>Next
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      formCopy: this.form,
      templates: [],
    };
  },
  methods: {
    next() {
      this.$emit("next");
    },
    async fetchTemplates() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/marketing-email-templates"
      );
      this.templates = data;
    },
  },
  created() {
    this.fetchTemplates();
  },
};
</script>

<style>
</style>
