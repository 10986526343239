<template>
  <div>
    <p class="alert alert-secondary mb-4">
      Now select the list of contacts you wish to send this campaign too, at the
      moment, one list per send-out is activated
    </p>
    <form @submit.prevent="next">
      <div class="row mb-4">
        <div class="col">
          <h5 class="text-secondary fw-bolder">Choose your marketing list</h5>
          <select v-model="formCopy.marketing_list_id" class="form-control">
            <option value="">Select marketing list</option>
            <option v-for="l in lists" :key="l.id" :value="l.id">
              {{ l.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="mb-0 fw-bold">
            Manage your marketing lists from
            <router-link to="/marketing-email-templates">here</router-link>
          </p>
        </div>
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="!formCopy.marketing_list_id || lists.length == 0"
          >
            <i class="far fa-arrow-right me-2"></i>Next
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      formCopy: this.form,
      lists: []
    };
  },
  methods: {
    next() {
      this.$emit("next");
    },
    async fetchLists() {
      const { data } = await this.$axios.get(process.env.VUE_APP_API_URL +"/marketing-lists");
      this.lists = data;
    }
  },
  created() {
    this.fetchLists();
  }
};
</script>

<style>
</style>