<template>
  <div>
    <p class="alert alert-secondary mb-4">
      Select the date and time you would like your marketing send-out to be
      sent.
      <br />
      <i
        ><small
          >This along with all other settings associated with the send-out can
          be modified up to the scheduled send time</small
        ></i
      >
    </p>
    <div class="row mb-4">
      <div class="col">
        <h5 class="text-secondary fw-bolder">Schedule a date &amp; time</h5>
        <datetime
          v-model="formCopy.send_at"
          type="datetime"
          :minute-step="15"
          class="form-control crank-date-select"
          style="white-space: normal"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-auto ms-auto">
        <button
          type="submit"
          :disabled="!formCopy.send_at"
          class="btn btn-secondary"
        >
          <i class="far fa-check me-2"></i>Create Send-out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

export default {
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      formCopy: this.form,
    };
  },
  methods: {
    next() {
      this.$emit("next");
    },
  },
  components: {
    Datetime,
  },
};
</script>

<style>
</style>