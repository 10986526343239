<template>
  <div>
    <p class="alert alert-secondary mb-4">
      Select the address from which this email will be sent to your chosen list.
      <br />
      To add different sender addresses you will need to
      <router-link to="/domains">manage your domain</router-link>
    </p>
    <form @submit.prevent="next">
      <div class="row mb-4">
        <div class="col">
          <h5 class="text-secondary fw-bolder">
            From which address are you sending
          </h5>
          <select
            v-model="form.authorised_domain_address_id"
            class="form-control"
          >
            <option value="">Select from address</option>
            <option v-for="d in addresses" :key="d.id" :value="d.id">
              {{ d.address_prefix }}@{{ d.domain.domain }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p class="mb-0 fw-bold">
            To manage your custom sender addresses
            <router-link to="/domains">view custom domain settings</router-link>
          </p>
        </div>
        <div class="col-auto ms-auto">
          <button type="submit" class="btn btn-primary">
            <i class="far fa-arrow-right me-2"></i>Next
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
    },
  },
  data() {
    return {
      formCopy: this.form,
      addresses: [],
    };
  },
  methods: {
    next() {
      this.$emit("next");
    },
    async fetchAddresses() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/authorised-domain-addresses"
      );
      this.addresses = data;
    },
  },
  created() {
    this.fetchAddresses();
  },
};
</script>

<style>
</style>