<template>
  <div>
    <p class="alert alert-secondary mb-4">
      Give your campaign a name, this is only used internally. The subject of
      your marketing send-out comes from your
      <router-link to="/marketing-email-templates">email templates</router-link>
    </p>
    <form @submit.prevent="next">
      <div class="row mb-4">
        <div class="col">
          <h5 class="text-secondary fw-bolder">Name your send-out</h5>
          <input
            type="text"
            class="form-control"
            v-model="formCopy.name"
            placeholder="Campaign 123"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-auto ms-auto">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="!formCopy.name"
          >
            <i class="far fa-arrow-right me-2"></i>Next
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object
    }
  },
  data() {
    return {
      formCopy: this.form
    };
  },
  methods: {
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style>
</style>