<template>
  <div class="container">
    <h1 class="fw-bold text-uppercase text-secondary mb-4">
      Create a Marketing Send-out
    </h1>
    <form @submit.prevent="createSendout">
      <div class="card border-0 shadow-none bg-light">
        <div class="card-body">
          <step-1 :form="form" v-if="step == 1" @next="step++"></step-1>
          <step-2 :form="form" v-if="step == 2" @next="step++"></step-2>
          <step-3 :form="form" v-if="step == 3" @next="step++"></step-3>
          <step-4 :form="form" v-if="step == 4" @next="step++"></step-4>
          <step-5 :form="form" v-if="step == 5" @next="createSendout"></step-5>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Step1 from "./create-partials/Step1";
import Step2 from "./create-partials/Step2";
import Step3 from "./create-partials/Step3";
import Step4 from "./create-partials/Step4";
import Step5 from "./create-partials/Step5";

export default {
  data() {
    return {
      step: 1,
      saving: false,
      form: {
        name: this.$route.query.name || "",
        marketing_list_id: "",
        marketing_email_template_id: "",
        authorised_domain_address_id: "",
      },
    };
  },
  methods: {
    async createSendout() {
      if (!this.saving) {
        this.saving = true;

        const { data } = await this.$axios.post(
          process.env.VUE_APP_API_URL + "/marketing-sendouts",
          this.form
        );

        this.form = {
          name: this.$route.query.name || "",
          marketing_list_id: "",
          marketing_email_template_id: "",
        };

        this.$router.push("/marketing-sendouts/" + data.sendout.id);

        this.$EventBus.$emit("alert", { message: data.message });
      }
    },
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
};
</script>

<style>
</style>
